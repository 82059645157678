@font-face {
    font-family: 'Gabriela';
    src: url('./Share/fonts/Gabriela-Regular.ttf');
    font-style: normal;
    font-weight: normal;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    color: #222222;
    font-family: 'Open Sans', sans-serif;
}

a {
    text-decoration: none;
}

body, html {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

.news-carousel {
    height: 100%;
}

.left {
    margin-right:50px;
}

.right {
    margin-left:50px;
}


/* AUTH FORMULARIOS */

.auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}


/* INPUT FORMULARIOS */

.form-input {
    padding: 10px;
    outline: none;
    border: 2px solid #BCBCBC;
    border-radius: 0.5em;
    font-size: 1em;
}

.form-input:focus {
    border: 2px solid #4F8FCBCC;
    box-shadow: 0 0 5px 1px #4F8FCB;
    transition: all 0.2s ease-in;
}

.form-input--password {
    border: none;
    outline: none;
    font-size: 1em;
    width: 100%;
}


/* CONTACTO */

.form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.checkbox {
    vertical-align: middle;
}


/* VALIDACION ERRORES */

.error + input, .error + textarea {
    border: 2px solid #E3090980;
    transition: all 0.2s ease-in;
}

.error + div .react-select__control {
    border: 2px solid #E3090980;
    transition: all 0.2s ease-in;
}

.error + div .react-select__control--is-focused {
    border: 2px solid #4F8FCBCC;
}

/* NOTICIA */

.news-content--title {
    font-family: 'Gabriela';
}

.news-content--text {
    line-height: 1.5;
}


/* CARRUSEL DE NOTICIAS */

.section-gray .slick-slide > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}


/* NAVLINKS */

.navlink {
    color: #FFFFFF;
    text-decoration: none;
    padding: 10px 10px 10px 15px;
}

.navlink.dropdown {
    color: #2474BF;
    display: flex;
}


/* CARD NOTICIA */

.news-bottom--title {
    font-family: 'Gabriela';
    margin-left: 10px;
}


/* BUSCADOR NOTICIAS */

.news-list--form {
    width: 50%;
    margin-bottom: 5vh;
}


/* LINKS */

.react-router--link {
    color: #4F8FCB;
    transition: all 0.2s ease;
    width: fit-content;
}

.news-link {
    margin: 30px;
}

.news-content--link {
    margin: 40px 0 0 20px;
    margin-top: auto;
}

.react-router--link:hover {
    color: #2474BF;
    transition: all 0.2s ease;
    text-decoration: underline;
}

.responsive-link {
    color: #FFFFFF;
    padding: 20px 0;
    justify-content: center;
    display: flex;
}

.footer-link {
    color: #FFFFFF;
    transition: all 0.2s ease;
    width: fit-content;
    margin: 0 10px 0 10px;
    padding: 5px;
    border-radius: 0.2em;
    transition: all 0.2s ease;
}

.footer-link.linkedin {
    margin: 0;
}

.footer-link:hover {
    /* color: #FFFFFFB3; */
    background-color: #6c9ecc;
    transition: all 0.2s ease;
}

.footer-link.maintenance {
    color: #222222;
}

.footer-link.maintenance:hover {
    background-color: #F3F5F6;
    transition: all 0.2s ease;
}

.admin-link {
    display: flex;
    align-items: center;
    padding: 10px 20px;
}

.admin-link > svg {
    margin-left: 5px;
}


/* ENTITY CARD */

.entity-card--text {
    margin-bottom: 15px;
}


/* BUSCADOR ENTIDADES-COMERCIOS */

.entity-list--form {
    width: 60%;
}


/* ERROR 404 */

.not-found--link {
    color: #FFFFFF;
    letter-spacing: 0.5px;
    background-color: #4F8FCB;
    padding: 15px 10px;
    margin-top: 5%;
    border-radius: 0.5em;
    cursor: pointer;
    border: 2px solid #4F8FCB;
    transition: all ease 0.2s;
}

.not-found--link:hover {
    background-color: #FFFFFF;
    color: #4F8FCB;
    transition: all ease 0.2s;
}

.animation-background {
    height: 80px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
}

.animation-wave {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
    position: absolute;
    width: 200%;
    height: 100%;
    animation: wave 23s -3s linear infinite;
    opacity: 0.8;
}

.animation-wave--second {
    bottom: 0;
    animation: wave 23s reverse linear infinite;
    animation-delay: 5s;
    opacity: 0.5;
}

.animation-wave--third {
    bottom: 0;
    animation: wave 23s -1s linear infinite;
    animation-delay: 9s;
    opacity: 0.5;
}

@keyframes wave {
    0% {transform: translateX(0);}
    50% {transform: translateX(-50%);}
    100% {transform: translateX(0);}
}


/* DESACTIVAR SCROLL */

.scroll-desactivado {
    height: 100%;
    overflow: hidden;
}


/* ANIMACION SELECT NOTICIAS */

.news-arrow {
    margin-right: 5px;
}

.news-arrow.hover {
    animation: bounceRight 1s linear infinite;
}

@keyframes bounceRight {
    0% {transform: translateX(0)}
    50% {transform: translateX(-8px)}
    100% {transform: translateX(0)}
}


/* CARRUSEL IMAGENES */

.gallery-carrousel .slick-slider.slick-initialized {
    padding: 0 3%;
}

.gallery-carrousel .slick-slide div {
    outline: none;
    padding: 10%;
}


/* ENLACES REDES SOCIALES FOOTER */

.footer-a {
    display: flex;
    margin-bottom: 3%;
}

.footer-a:hover > p {
    background-color: #6c9ecc;
    transition: background-color 0.2s ease;
}


/* LOADER */

.bounce-loader {
    position: absolute;
    height: 100vh;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
    background-color: rgba(0,0,0,0.5);
}

.bounce-loader.active {
    display: flex;
}


/* RESPONSIVE */

@media (max-width: 1220px) {

    .entity-list--form {
        width: 82%;
    }

}

@media (max-width: 780px) {

    .news-content--link {
        align-self: flex-end;
        margin: 0;
        margin-top: auto;
    }

    .news-content--text {
        display: none;
    }

    .entity-link {
        display: flex;
        justify-content: center;
    }

}

@media (max-width: 690px) {

    .footer-a {
        margin-bottom: 1.5%;
    }

}

@media (max-width: 650px) {

    .news-content--link {
        margin-top: 20px;
    }

    .news-list--form {
        width: 60%;
        margin-top: 5%;
    }

}

@media (max-width: 480px) {

    .news-list--form {
        width: 70%;
        margin-top: 15%;
    }

    .entity-card--text {
        display: none;
    }

}

@media (max-width: 451px) {

    .footer-link {
        margin: 0;
        margin-left: 5px;
    }

}
